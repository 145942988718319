import { ORDER_TYPE } from '../../utils/Utils.js'

const orderPickupInstructions = [
  {
    icon: 'shipping',
    fragments: [
      { text: 'Dodanie do lekárne z' },
      {
        text: 'online obchodu',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Hneď ako bude Vaša objednávka pripravená na vyzdvihnutie, kontaktujeme Vás prostredníctvom SMS alebo e-mailu.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Objednávku si môžete vyzdvihnúť' },
      {
        text: 'maximálne do 3 dní od jej doručenia do lekárne.',
        isBold: true,
      },
    ],
  },
]

const reservationPickupInstructions = [
  {
    icon: 'pharmacy',
    fragments: [
      { text: 'Vyzdvihnutie v' },
      {
        text: 'lekárni',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Hneď ako bude Vaša rezervácia pripravená na vyzdvihnutie, kontaktujeme Vás prostredníctvom SMS alebo e-mailu.',
        isBold: true,
      },
    ],
  },
  {
    icon: 'calendar',
    fragments: [
      { text: 'Rezerváciu si môžete vyzdvihnúť' },
      {
        text: 'do 48 hodín od potvrdenia lekárňou.',
        isBold: true,
      },
    ],
  },
]

const rxPickupInstructions = [
  {
    icon: 'pharmacy',
    fragments: [
      { text: 'Vyzdvihnutie v' },
      {
        text: 'lekárni',
        isBold: true,
      },
    ],
  },
  {
    icon: 'sms',
    fragments: [
      {
        text: 'Hneď ako bude Vaša rezervácia pripravená na vyzdvihnutie, kontaktujeme Vás prostredníctvom SMS alebo e-mailu.',
        isBold: true,
      },
    ],
  },
]

export default {
  orderLabel: 'Objednávka z e-shopu',
  reservationLabel: 'Rezervácia v lekárni',
  rxReservationLabel: 'Rezervácia liekov na predpis v lekárni',
  unavailablePickupLabel: 'Vyzdvihnutie na lekárni nie je k dispozícii',
  selectedPlaceLabel: 'Vybraná lekáreň',
  confirmButtonLabel: 'Vyberte lekáreň',
  inStockLabel: 'Skladom',
  onOrderLabel: 'Na objednávku',
  rxReservationButtonLabel: 'Rezervovať',
  rxOrderButtonLabel: 'Rezervovať na\xa0objednávku',
  getTitle() {
    return `Lekáreň Dr.Max, ${this.title}`
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions(orderType) {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: (() => {
        switch (orderType) {
          case ORDER_TYPE.RX_ORDER:
          case ORDER_TYPE.RX_RESERVATION:
            return rxPickupInstructions
          case ORDER_TYPE.ORDER:
            return orderPickupInstructions
          case ORDER_TYPE.RESERVATION:
            return reservationPickupInstructions
          default:
            break
        }

        return []
      })(),
    }
  },
  pins: {
    enabled: 'pin-pharmacy-enabled.svg',
    disabled: 'pin-pharmacy-disabled.svg',
    selected: 'pin-pharmacy-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/cac.svg',
}
