import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import modules from './modules.mjs'
import elastic from './elastic.mjs'
import global from './global.mjs'
import seo from './seo.mjs'
import app from '../app.mjs'
import apotheka from '../apotheka.mjs'
import rxOnline from '../rxOnline.mjs'
import navigation from '../navigation.mjs'
import cloudflare from '../cloudflare.mjs'
import feedaty from '../feedaty.mjs'
import sukl from '../sukl.mjs'
import trovaprezzi from '../trovaprezzi.mjs'

export default defineAppConfig({
  facebook: {
    domainVerificationCode: 'r7ootzr05g40i2xxhaf0qzynvezsyq',
  },
  ...modules,
  ...elastic,
  ...global,
  ...seo,
  ...app,
  ...apotheka,
  ...rxOnline,
  ...navigation,
  ...cloudflare,
  ...sukl,
  ...feedaty,
  ...trovaprezzi,
})
