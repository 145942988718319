export default {
  orderModuleConfig: {
    deliveryGroupMapping: {
      'pickup_place_cc': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvorená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Nezaplatená',
          text: 'Skontrolujte si prosím svoju e-mailovú schránku (vrátane priečinka SPAM) a zaplaťte objednávku podľa pokynov.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Zaplatená',
          text: '',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Spracováva sa',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Problém so spracovaním',
          text: 'Prosím, kontaktujte priamo zákaznícky servis na telefónnom čísle <a href="tel:0800606070">0800606070</a> alebo e-mailovej adrese <a href="mailto:drmax@drmax.sk">drmax@drmax.sk</a>, aby ste získali ďalšie podrobnosti o tom, čo sa stalo s Vašou objednávkou alebo rezerváciou.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'Problém so spracovaním',
          text: 'Prosíme o trpezlivosť. Objednaný produkt bohužiaľ nie je momentálne skladom, čakáme na doskladnenie.',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'Pripravená na odoslanie',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Odoslaná',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'Pripravená na vyzdvihnutie',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Prevzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Ak chcete získať ďalšie podrobnosti o svojej objednávke, kontaktujte náš zákaznícky servis na telefónnom čísle <a href="tel:0800606070">0800606070</a> alebo e-mailovej adrese  <a href="mailto:drmax@drmax.sk">drmax@drmax.sk</a>.',
          variant: 'error',
          parent: '',
        },
      ],
      'pickup_place_box': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvorená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Nezaplatená',
          text: 'Skontrolujte si prosím svoju e-mailovú schránku (vrátane priečinka SPAM) a zaplaťte objednávku podľa pokynov.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Spracováva sa',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Problém so spracovaním',
          text: 'Prosím, kontaktujte priamo zákaznícky servis na telefónnom čísle <a href="tel:0800606070">0800606070</a> alebo e-mailovej adrese <a href="mailto:drmax@drmax.sk">drmax@drmax.sk</a>, aby ste získali ďalšie podrobnosti o tom, čo sa stalo s Vašou objednávkou alebo rezerváciou.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'Čaká na naskladnenie',
          text: 'Prosíme o trpezlivosť. Objednaný produkt bohužiaľ nie je momentálne skladom, čakáme na doskladnenie.',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'Pripravená na odoslanie',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Odoslaná',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'Pripravená na vyzdvihnutie',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Prevzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Ak chcete získať ďalšie podrobnosti o svojej objednávke, kontaktujte náš zákaznícky servis na telefónnom čísle <a href="tel:0800606070">0800606070</a> alebo e-mailovej adrese  <a href="mailto:drmax@drmax.sk">drmax@drmax.sk</a>.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_expired',
          label: 'Expirovaná',
          text: 'Čas na vyzdvihnutie objednávky uplynul. Vytvorte si objednávku nanovo, prosím.',
          variant: 'error',
          parent: 'canceled',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Vracia sa späť do skladu',
          text: 'Objednávka nebola doručená alebo vyzdvihnutá, balík je na ceste do skladu. Vytvorte si objednávku nanovo, prosím.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'delivery': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvorená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'unpaid',
          label: 'Nezaplatená',
          text: 'Skontrolujte si prosím svoju e-mailovú schránku (vrátane priečinka SPAM) a zaplaťte objednávku podľa pokynov.',
          variant: 'warning',
          parent: 'new',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Zaplatená',
          text: '',
          variant: 'success',
          parent: 'new',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Spracováva sa',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Problém so spracovaním',
          text: 'Prosím, kontaktujte priamo zákaznícky servis na telefónnom čísle <a href="tel:0800606070">0800606070</a> alebo e-mailovej adrese <a href="mailto:drmax@drmax.sk">drmax@drmax.sk</a>, aby ste získali ďalšie podrobnosti o tom, čo sa stalo s Vašou objednávkou alebo rezerváciou.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'inprogress_backordered',
          label: 'Čaká na naskladnenie',
          text: 'Prosíme o trpezlivosť. Objednaný produkt bohužiaľ nie je momentálne skladom, čakáme na doskladnenie.',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'connector',
          key: 'packed',
          label: 'Pripravená na odoslanie',
          text: '',
          variant: 'info',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Odoslaná',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'Pripravená na vyzdvihnutie',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Prevzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Ak chcete získať ďalšie podrobnosti o svojej objednávke, kontaktujte náš zákaznícky servis na telefónnom čísle <a href="tel:0800606070">0800606070</a> alebo e-mailovej adrese  <a href="mailto:drmax@drmax.sk">drmax@drmax.sk</a>.',
          variant: 'error',
          parent: '',
        },
        {
          type: 'connector',
          key: 'canceled_expired',
          label: 'Expirovaná',
          text: 'Čas na vyzdvihnutie objednávky uplynul. Vytvorte si objednávku nanovo, prosím.',
          variant: 'error',
          parent: 'canceled',
        },
        {
          type: 'connector',
          key: 'canceled_returned',
          label: 'Vracia sa späť do skladu',
          text: 'Objednávka nebola doručená alebo vyzdvihnutá, balík je na ceste do skladu. Vytvorte si objednávku nanovo, prosím.',
          variant: 'error',
          parent: 'canceled',
        },
      ],
      'reservation': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvorená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Spracováva sa',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_error',
          label: 'Problém so spracovaním',
          text: 'Prosím, kontaktujte priamo zákaznícky servis na telefónnom čísle <a href="tel:0800606070">0800606070</a> alebo e-mailovej adrese <a href="mailto:drmax@drmax.sk">drmax@drmax.sk</a>, aby ste získali ďalšie podrobnosti o tom, čo sa stalo s Vašou objednávkou alebo rezerváciou.',
          variant: 'error',
          parent: 'inprogress',
        },
        {
          type: 'milestone',
          key: 'ready_for_pickup',
          label: 'Pripravená na vyzdvihnutie',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Prevzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Ak chcete získať ďalšie podrobnosti o svojej objednávke, kontaktujte náš zákaznícky servis na telefónnom čísle <a href="tel:0800606070">0800606070</a> alebo e-mailovej adrese  <a href="mailto:drmax@drmax.sk">drmax@drmax.sk</a>.',
          variant: 'error',
          parent: '',
        },
      ],
      'delivery_mp': [
        {
          type: 'milestone',
          key: 'inprogress_payment_complete',
          label: 'Vytvorená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'connector',
          key: 'inprogress_payment_complete',
          label: 'Zaplatená',
          text: '',
          variant: 'success',
          parent: 'inprogress_payment_complete',
        },
        {
          type: 'milestone',
          key: 'inprogress',
          label: 'Spracováva sa',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'shipping',
          label: 'Odoslaná',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Prevzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Ak chcete získať ďalšie podrobnosti o svojej objednávke, kontaktujte náš zákaznícky servis na telefónnom čísle <a href="tel:0800606070">0800606070</a> alebo e-mailovej adrese  <a href="mailto:drmax@drmax.sk">drmax@drmax.sk</a>.',
          variant: 'error',
          parent: '',
        },
      ],
      'pharmacy_purchase': [
        {
          type: 'milestone',
          key: 'new',
          label: 'Vytvorená',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'delivered',
          label: 'Prevzatá',
          text: '',
          variant: 'success',
          parent: '',
        },
        {
          type: 'milestone',
          key: 'canceled',
          label: 'Zrušená',
          text: 'Ak chcete získať ďalšie podrobnosti o svojej objednávke, kontaktujte náš zákaznícky servis na telefónnom čísle <a href="tel:0800606070">0800606070</a> alebo e-mailovej adrese  <a href="mailto:drmax@drmax.sk">drmax@drmax.sk</a>.',
          variant: 'error',
          parent: '',
        },
      ],
    },
  },
}
