import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'

export default defineAppConfig({
  allowedSources: [
    '*.algolia.net',
    '*.algolianet.com',
    '*.clarity.ms',
    '*.drmax-gl.dev',
    '*.drmax-gl.space',
    '*.drmax-sk.space',
    '*.drmax.net',
    '*.drmax.sk',
    '*.drmax.zone',
    '*.drmax-gl.live',
    '*.facebook.com',
    '*.facebook.net',
    '*.fbcdn.net',
    '*.google-analytics.com',
    '*.google.com',
    '*.googleapis.com',
    '*.instagram.com',
    '*.sentry.io',
    '*.twitter.com',
    'api.luigisbox.com',
    'bam.eu01.nr-data.net',
    'bat.bing.com',
    'cdn.evgnet.com',
    'cdn.jsdelivr.net',
    'cdn.luigisbox.com',
    'cdn.speedcurve.com',
    'cdn-4.convertexperiments.com',
    'cdp.drmaxsk.meiro.io',
    'cj.dotomi.com',
    'consent.cookiebot.com',
    'consentcdn.cookiebot.com',
    'ct.pinterest.com',
    'drmaxbdcsro.germany-2.evergage.com',
    'drmaxsvkepmhub.azurewebsites.net',
    'drmaxsvkepmhubtest.azurewebsites.net',
    'dynamic.criteo.com',
    'fledge.eu.criteo.com',
    'fonts.gstatic.com',
    'googleads.g.doubleclick.net',
    'gum.criteo.com',
    'im9.cz',
    'image-resizer-svc.drmax-gl.live',
    'image-resizer-svc.drmax-gl.space',
    'inres.uspech.sk',
    'insights.algolia.io',
    'js-agent.newrelic.com',
    'manager.eu.smartlook.cloud',
    'measurement-api.criteo.com',
    'pagead2.googlesyndication.com',
    'placement-service.drmax-gl.live',
    'placement-service.drmax-gl.space',
    'rec.smartlook.com',
    'rtp.persoo.ai',
    's.pinimg.com',
    's2.adform.net',
    'scripts.persoo.cz',
    'search-service.drmax-gl.space',
    'sslwidget.criteo.com',
    'static.cloudflareinsights.com',
    'static.criteo.net',
    'stats.g.doubleclick.net',
    'td.doubleclick.net',
    'track.adform.net',
    'unpkg.com',
    'webchat.drmax.sk',
    'www.googleoptimize.com',
    'www.googletagmanager.com',
    'www.gstatic.com',
    'www.kdukvh.com',
    'www.recaptcha.net',
    'www.redditstatic.com',
    'www.youtube-nocookie.com',
    'www.youtube.com',
  ],
})
